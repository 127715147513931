import React from "react"
import Layout from "../components/note-layout"
import { Helmet } from "react-helmet";
import "../components/about.css"
import "../components/portfolio.css"


export default function About() {
  return (
    <Layout>
    <Helmet>
      <title>
        About
      </title>
    </Helmet>
    <div>
      <h1>About</h1>
      <p className="page-para">
        Hey there, I'm Malavika –– a junior at Parsons School of Design from Bangalore, India. I'm extremely interested in <a href="../notes/multi-disciplinary">multi-disciplinary design</a>. A common trend in my interests seems to be motion/movement –– whether that's animation, graphic novels, interactive websites, dance or puppies. My interests also lie in philosophy, psychology, and education.
        <br/><br/>
        <a href="../notes/now">Now</a> is a list of things I'm thinking about and working on at the moment.
        <br/>
        If you're curious to know the technology behind this website you can find it here: <a href="../notes/how-i-built-this-site">How I Built This Site</a>
      </p>
      <br/>
      <p className="page-para">
            Say hi! I'm always happy to talk about collaborating, potential internship and work opportunities, or if you just want to be friends. (:
      </p>
      <div className="contact">

      Contact:
      <div className="break">
      <a href="mailto:hello@malavikainmotion.com">hello@malavikainmotion.com</a><br/>
      <a href="https://www.instagram.com/malavikainmotion/">Instagram</a>
      </div>
      </div>

    </div>

    <div className="bottom">
      © Malavika Srinivasan, 2021.
    </div>
    </Layout>
  )
}
